import React from 'react';
import PropTypes from 'prop-types';
import Message from './Message.js';

const MessageList = (props) => {
  const messages = props.messages.map((m,i)=>(
    <Message from={m.from} key={i} seen={m.seen} onSeen={props.onSeen} next={m.next}>{m.text}</Message>
  ));

  return (
    <ul className="messageList">
      {messages}
    </ul>
  );
};

MessageList.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default MessageList;

export const addMessage = ({text, threadName,from, next}) => ({
  type: 'ADD_MESSAGE',
  threadName,
  from,
  text,
  next
});

export const markSeen = ({threadName, next}) => ({
  type: 'MARK_SEEN',
  threadName,
  next
})

export const markRead = (threadName) => ({
  type: 'MARK_READ',
  threadName
});

export const addChoice = ({threadName,options}) => ({
  type: 'ADD_CHOICE',
  options,
  threadName
})

export const removeChoice = (threadName) => ({
  type: 'REMOVE_CHOICE',
  threadName
});

export const beginGame = () => ({
  type: 'BEGIN_GAME'
});

import {connect} from 'react-redux';
import {beginGame} from '../actions/';
import Intro from '../components/Intro';

const mapStateToProps = (state,ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    beginGame: () => {
      dispatch(beginGame());
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Intro);

import React from 'react';
import PropTypes from 'prop-types';
import ThreadToolbar from './ThreadToolbar.js';
import ThreadList from './ThreadList.js';
import IntroContainer from '../containers/IntroContainer.js';

const ThreadWindow = (props) => {
  if (props.begun) {
    return (
      <div className="threadWindow-container">
        <div className='threadWindow-container__toolbar'>
          <ThreadToolbar/>
        </div>
        <div className='threadWindow-container__list'>
          <ThreadList threads={props.threads} />
        </div>
      </div>
    );

  } else {
    return (
      <IntroContainer/>
    );
  }

}

ThreadWindow.propTypes = {
  threads: PropTypes.array.isRequired
};

export default ThreadWindow;

import React from 'react';
import PropTypes from 'prop-types';
import './Thread.css';
import {Link} from 'react-router-dom';

const Thread = (props) => {
  const unreadClass = (props.hasUnread) ? 'thread--hasUnread':'';
  const classes = `thread ${unreadClass}`;
  const href = `/thread/${props.threadName}`; // TODO: We should be using an ID here

  return (
    <li className={classes}>
      <Link to={href} className="thread__link">
        <img
          src={`/avatars/${props.threadName}.jpg`}
          alt={props.threadName}
          className="thread__photo"/>
        <p className="thread__text">{props.threadName}</p>
      </Link>
    </li>
  )
};

Thread.propTypes = {
  threadName: PropTypes.string.isRequired,
  hasUnread: PropTypes.bool.isRequired
}

export default Thread;

import React from 'react';
import './App.css';
import ThreadWindowContainer from '../containers/ThreadWindowContainer';
import ChatWindowContainer from '../containers/ChatWindowContainer';
import About from '../components/About.js';
import End from '../components/End.js';
import {Switch,Route} from 'react-router-dom';
import { AnimatePresence } from "framer-motion";


function App() {
  return (
    <div className="App">
      <AnimatePresence>
        <Switch>
          <Route path="/" exact>
            <ThreadWindowContainer/>
          </Route>
          <Route path="/thread/:threadName" component={ChatWindowContainer} />
          <Route path="/about" component={About} />
          <Route path="/end" component={End} />
        </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;

import { connect } from 'react-redux';
import {
  markRead,
  markSeen,
  addMessage,
  addChoice,
  removeChoice
} from '../actions/';
import ChatWindow from '../components/ChatWindow';

const mapStateToProps = (state,ownProps) => {
  var threadName = ownProps.match.params.threadName;
  var thread = state.threads.filter((t) => (t.threadName === threadName))[0];
  return {
    threadName,
    messages: thread.messages || [],
    hasUnread: thread.hasUnread,
    options: thread.options
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    markRead: (threadName) => {
      dispatch(markRead(threadName));
    },
    markSeen: (threadName, next) => {
      dispatch(markSeen(threadName, next))
    },
    addMessage: (message) => {
      dispatch(addMessage(message));
    },
    addChoice: (choice) => {
      dispatch(addChoice(choice));
    },
    removeChoice: (threadName) => {
      dispatch(removeChoice(threadName));
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatWindow);

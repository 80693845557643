import React from 'react';
import PropTypes from 'prop-types';
import Thread from './Thread.js';

const ThreadList = (props) => {
  const threads = props.threads.map((t,i)=>(
    <Thread key={i} hasUnread={t.hasUnread} threadName={t.threadName}/>
  ));

  return (
    <ul className="threadList">
      {threads}
    </ul>
  );
};

ThreadList.propTypes = {
  threads: PropTypes.array.isRequired,
};

export default ThreadList;

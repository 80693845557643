const defaultStartState = {
  threads: [
    {
      threadName: 'Admin',
      hasUnread: true,
      messages: [
        {from: 'A', text: 'Hello Cyborg1792! Welcome to NightWatch! ', next: 'intro.1'} // Should be intro.1
      ],
      options: []
    }
  ],
  begun: false
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return defaultStartState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return defaultStartState;
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state',serializedState)
  } catch (err) {
    console.log(err);
  }
}

import React from 'react';
import './About.css';


const End = (props) => {
  const reset = () => {
    if(window.confirm("Are you sure you want to reset the game? This cannot be undone.")){
      window.localStorage.clear();
      window.location= "/";
    }
  }

  return (
    <div className="About">
      <div className="About__toolbar">
        The End
      </div>
      <div className="About__content">
        <img src="/end.png" alt="The end" className="About__content_banner"/>
        <br/><br/>
        <p className="About__content__text">
          Thank you for playing! Frisk & Floss was created by <a href="https://imissmyfriends.studio/" target="_blank" rel="noopener noreferrer">imissmyfriends.studio</a>.
          We are now working on developing this into a larger game.
          Join us on our <a href="https://discord.com/invite/n5gaXVgDbU" target="_blank" rel="noopener noreferrer">Discord</a> to chat with us and stay updated.
        </p>

        <p className="About__content__help">
          Want to play again? <br/>
          <button onClick={reset} className="About__content_reset-button">Reset the game</button>
        </p>
      </div>
    </div>
  );
}

export default End;

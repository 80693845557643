import React from 'react';
import './Intro.css';

class Intro extends React.Component {
  cont() {
    this.props.beginGame();
  }

  render () {
    return (
      <div className="intro">
        <span className="glitch" data-text="Frisk">Frisk</span>
        <span className="glitch" data-text="Be safe.">Be safe.</span>
        <span className="glitch" data-text="Be careful.">Be careful.</span>
        <span className="glitch" data-text="All the best for your mission.">All the best for your mission.</span>
        <span className="glitch" data-text="Remember AI are not to be trusted.">Remember AI are not to be trusted.</span>
        <span className="glitch" data-text="We will meet again.">We will meet again.</span>
        <span className="glitch" data-text="Until then.">Until then.</span>
        <span className="glitch" data-text="Stay well.">Stay well.</span>
        <span className="glitch" data-text="We wi…">We wi…</span>
        <button onClick={this.cont.bind(this)} className="intro__what">Wait…</button>
      </div>
    );
  }
};

export default Intro;

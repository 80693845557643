import React from 'react';
import PropTypes from 'prop-types';
import './ChatToolbar.css';
import {Link} from 'react-router-dom';

const ChatToolbar = (props) => (
  <div className="chatToolbar">
    <Link to="/" className="chatToolbar__back-link">
      <img
        src="/back_icon.svg"
        className="chatToolbar__back-icon"
        alt="Back"/>
    </Link>
    <img
      src={`/avatars/${props.threadName}.jpg`}
      alt={props.threadName}
      className="chatToolbar__photo"/>
    <h1 className="chatToolbar__title">{props.threadName}</h1>
  </div>
)

ChatToolbar.propTypes = {
  threadName: PropTypes.string.isRequired
};

export default ChatToolbar;

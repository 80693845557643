import React from 'react';
import {Link} from 'react-router-dom';
import './About.css';


const About = (props) => {
  const reset = () => {
    if(window.confirm("Are you sure you want to reset the game? This cannot be undone.")){
      window.localStorage.clear();
      window.location= "/";
    }
  }

  return (
    <div className="About">
      <div className="About__toolbar">
        About
        <Link to="/">
          <img src="/close.svg" alt="Close" className="About__toolbar__icon"/>
        </Link>
      </div>
      <div className="About__content">
        <img src="/cover.png" alt="Frisk & Floss. A tiny text adventure." className="About__content_banner"/>
        <br/>
        <br/>

        <p className="About__content__text">
          Frisk & Floss is an interactive fiction story by <a href="https://imissmyfriends.studio/" target="_blank" rel="noopener noreferrer">imissmyfriends.studio</a>.
          We are a two person, part-time indie game studio, learning to build fun and meaningful games.
          Join us on our <a href="https://discord.com/invite/n5gaXVgDbU" target="_blank" rel="noopener noreferrer">Discord</a> to chat with us and stay updated.
        </p>

        <br/>
        <p className="About__content__help">
          Stuck somewhere, or want to play again? <br/>
          <button onClick={reset} className="About__content_reset-button">Reset the game</button>
        </p>
      </div>
    </div>
  );
}

export default About;

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
import './Message.css';

const Message = (props) => {
  var initial;
  // TODO: Who is Z?
  var classNames = `message ${(props.from === 'Z') ? 'message--you':'message--'+props.from}`;
  var messageEl = props.children;

  // Random number generator
  function getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  // Set animation state to false if the message has been seen
  // Otherwise set the initial frame
  if (props.seen) {
    initial = false;
  } else {
    // Show animation
    initial = { opacity: 0, y: 10, scale: 0.3 };

    // Set CSS for different color
    // (not if the message is from the player)
    if (props.from !== 'Z') {
      classNames += ' message--unseen';
    }

    // Pick a character and make it stutter
    if (
      getRandomArbitrary(0, 1) === 0 && // 50% chance
      props.from === 'F' // Message from Floss
    ){
      var splitMessage = messageEl.split('');
      var animationDuration = getRandomArbitrary(300,1200);
      var animationIterationCount = Math.round(animationDuration / 300);

      // Change the letter that is a 1/3rd into the sentence
      var stutterMessage = '<span class="message__text--stutter"'
        + ' style="animation-duration: '+animationDuration+'ms;'
        + ' animation-iteration-count: '+animationIterationCount+'">'
        + splitMessage[Math.round(splitMessage.length / 3)]
        + '</span>';
      splitMessage[Math.round(splitMessage.length / 3)] = stutterMessage;
      messageEl = splitMessage.join('');
    }


    // Call the prop function to mark the message as seen
    window.setTimeout(function () {
      props.onSeen(props.next);
    }, 2000)
  }

  messageEl = {__html:messageEl};

  return (<motion.li
    className={classNames}
    positionTransition
    initial={initial}
    animate={{ opacity: 1, y: 0, scale: 1 }}
  >
    <img
      src={`/avatars/${props.from}.png`}
      alt={props.from}
      className="message__avatar"/>
    <span className="message__text" dangerouslySetInnerHTML={messageEl}>
    </span>
  </motion.li>);
};

Message.propTypes = {
  from: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  seen: PropTypes.bool.isRequired
};

export default Message;

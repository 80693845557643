import React from 'react';
import {Link} from 'react-router-dom';
import './ThreadToolbar.css';

const ThreadToolbar = () => (
  <div className="threadToolbar">
    <h1 className="threadToolbar__title">NightWatch Communicator</h1>
    <Link to="/about">
      <img src="/info.svg" alt="Information" className="threadToolbar__icon"/>
    </Link>
  </div>
)

export default ThreadToolbar;

import React from 'react';
import PropTypes from 'prop-types';
import ChatToolbar from './ChatToolbar.js';
import MessageList from './MessageList.js';
import Choice from './Choice.js';

import STORY from '../story.js';
import { motion } from "framer-motion"
import './FriskDiary.trump.css';

class ChatWindow extends React.Component {
  state = {};

  componentDidMount() {
    this.scrollToBottom();

    this.props.markRead(this.props.threadName);
    this.noOfMessages = this.props.messages.length;
    this.getNextStep()
  }

  componentDidUpdate() {
    this.scrollToBottom();

    if (this.noOfMessages === this.props.messages.length) return;
    this.noOfMessages = this.props.messages.length;

    if (this.props.hasUnread) {
      this.props.markRead(this.props.threadName);
    }

    this.getNextStep();
  }

  getNextStep() {
    const lastMessage = this.props.messages[this.props.messages.length - 1];
    if (lastMessage.next === undefined) return;

    // The next message could be a specific message, or just a scene.
    // If it is a scene, then we want to access the first dialog.
    var nextMessage;
    if (STORY[lastMessage.next] !== undefined) {
      nextMessage = STORY[lastMessage.next];
    } else if (STORY[lastMessage.next + '.1'] !== undefined ) {
      nextMessage = STORY[lastMessage.next+'.1'];
    }
    if (nextMessage === undefined) return;

    if (nextMessage.type === 'message') {
      let timeToType = this.calculateTypingTime(lastMessage.text)

      this.setState({
        typing: nextMessage.from
      });

      window.setTimeout(()=> {
        this.props.addMessage({
          threadName: nextMessage.threadName,
          text: nextMessage.text,
          from: nextMessage.from,
          next: nextMessage.next
        })
      }, timeToType)
    }

    if (nextMessage.type === 'choice') {
      // We want a slight delay between the last message and the choice showing
      // This also means that when you open the window that is waiting for a
      // choice there is a slight delay after which the choice opens.

      // TODO: This is hacky
      this.setState({
        typing: 'wait'
      });
      window.setTimeout(()=> {
        // TODO: This is supporting above hack
        this.setState({
          typing: undefined
        });
        this.props.addChoice({
          threadName: nextMessage.threadName,
          options: nextMessage.options
        })
      }, 1000);
    }

    if(nextMessage.type === 'transition') {
      var tNext = STORY[nextMessage.next];

      if (tNext.type === 'message') {
        // If the next scene is in the same thread then setting the typing variable
        // otherwise set it to undefined so that the Back to messages button can show
        let whoIsTyping = (tNext.threadName === this.props.threadName) ? tNext.from : undefined;

        // Set time to type to 0 if the message is another thread
        let timeToType = (whoIsTyping === undefined) ? 0 : this.calculateTypingTime(tNext.text)

        this.setState({
          typing: whoIsTyping
        });

        window.setTimeout(()=> {
          this.props.addMessage({
            threadName: tNext.threadName,
            text: tNext.text,
            from: tNext.from,
            next: tNext.next
          })
        }, timeToType);


      } else if (tNext.type === 'choice') {
        this.props.addChoice({
          threadName: tNext.threadName,
          options: tNext.options
        })
      }

    }
  }

  calculateTypingTime(text) {
    // Set typing time based on word count, of the previous message
    let approximateWordCount = text.length / 6;
    // Minimum time should be 600ms
    let timeToType = 1000 + (400 * approximateWordCount);

    return timeToType;
  }

  scrollToBottom() {
    document.getElementsByClassName("chatWindow-container__messages")[0].scrollTop = this.messagesEnd.offsetTop;
  }

  pickChoice(i) {
    var option = this.props.options[i];

    // TODO: This is a hack.
    if (option.text === '0htr43kr4d73j0rp') {
      this.props.history.push("/end");
    }

    this.props.removeChoice(this.props.threadName);
    this.props.addMessage({
      threadName: this.props.threadName,
      text: option.text,
      from: 'Z',
      next: option.next
    });
  }

  markSeen(next) {
    this.props.markSeen({
      threadName: this.props.threadName,
      next
    });
  }

  render () {
    return (
      <motion.div
        className="chatWindow-container"
        transition={{type: 'tween'}}
        initial={{opacity: 0.3, x: '100%', y:0}}
        animate={{opacity: 1, x: 0, y:0}}
        exit={{opacity: 0}}
      >
        <div className={`chatWindow-container__toolbar chatWindow-container__toolbar--${this.props.threadName}`}>
          <ChatToolbar threadName={this.props.threadName}/>
        </div>
        <div className={`chatWindow-container__messages chatWindow-container__messages--${this.props.threadName}`}>
          <MessageList messages={this.props.messages} onSeen={this.markSeen.bind(this)} />
          <div style={{ float:"left", clear: "both", height:"6rem", width: "100%" }}
             ref={(el) => { this.messagesEnd = el; }}>&nbsp;
          </div>
        </div>
        <div className={`chatWindow-container__choice chatWindow-container__choice--${this.props.threadName}`}>
          <Choice options={this.props.options} onPick={this.pickChoice.bind(this)} typing={this.state.typing}/>
        </div>
      </motion.div>
    );
  }
};

ChatWindow.propTypes = {
  threadName: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired
};

export default ChatWindow;

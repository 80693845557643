import React from 'react';
import PropTypes from 'prop-types';
import './Choice.css';
import {Link} from 'react-router-dom';
import { motion } from "framer-motion"

const Choice = (props) => {
  // TODO: This is hacky
  if (props.typing === 'wait') {
    return (
      <div className='choice'>
        <div className='choice__noChoice'>
          <div>
            &nbsp;
          </div>
        </div>
      </div>
    );
  }

  if (
    props.typing === undefined &&
    (
      props.options === undefined ||
      props.options.length === 0
    )
  ) {
    return (
      <div className='choice'>
        <Link to="/" className="choice__option">
          <img
            src="/back_icon.svg"
            className="choice__back-icon"
            alt="Back"/>
          &nbsp; Back to messages
        </Link>
      </div>
    );
  }


  if (
    props.options === undefined ||
    props.options.length === 0
  ) {
    return (
      <div className='choice'>
        <div className='choice__noChoice'>
          <div>
            {props.typing || 'No one'} is typing
          </div>
          <div className="choice__typing">
            <div></div><div></div><div></div>
          </div>
        </div>
      </div>
    );
  }

  const options = props.options.map((o, i) => (
    <button className="choice__option" key={i} onClick={props.onPick.bind(this, i)}>{o.text}</button>
  ));

  return (
    <motion.div
      className="choice"
      transition={{type: 'tween', duration: 0.2}}
      initial={{opacity: 0, scaleY: 0, originY: 1}}
      animate={{opacity: 1, scaleY:1}}
      exit={{opacity: 0, scaleY: 0}}
    >
      {options}
    </motion.div>
  );
};

Choice.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  typing: PropTypes.string
};

export default Choice;
